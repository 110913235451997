import {
	AfterViewInit,
	Component,
	EventEmitter,
	HostListener,
	Input, OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { PopoverComponent } from '../popover/popover.component';
import { DateFormattingService } from '../../../app/services/date-formatting.service';

@Component({
	selector: 'mos-date-picker',
	templateUrl: './date-picker.component.html',
})
export class DatePickerComponent implements AfterViewInit, OnChanges {
	@ViewChild('monthPopover') public monthPopover!: PopoverComponent;
	@ViewChild('yearPopover') public yearPopover!: PopoverComponent;
	@Input() disabled = false;
	@Input() singleDateInput: Date;
	@Input() dateRangeInput: { startDate: Date; endDate: Date };
	@Input() singleDate: boolean = false;
	@Input() dateRange: boolean = true;
	@Input() clearInput: boolean = false;
	@Output() selectedDate: EventEmitter<Date | null> = new EventEmitter<Date | null>();
	@Output() selectedRange: EventEmitter<{ from: Date | null; to: Date | null }> = new EventEmitter<{ from: Date | null; to: Date | null }>();

	public isOpen = false;
	public selectedSingleDate: Date | null = null;
	public selectedRangeValue: { from: Date | null; to: Date | null } = { from: null, to: null };
	public months = Array.from({ length: 12 }, (_, i) => i);
	public yearRange = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - 70 + i);
	public month: number = new Date().getMonth();
	public year: number = new Date().getFullYear();
	public daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
	public daysCache: { day: number, empty: boolean }[] | null = null;
	public dateRangeString: string = '';

	constructor(
		private dateFormattingService: DateFormattingService,
		) {
	}

	@HostListener('document:click', ['$event'])
	handleClickOutside(event: MouseEvent) {
		const target = event.target as HTMLElement;
		if (this.isOpen && !target.closest('.date-picker-container')) {
			this.isOpen = false;
		}
	}

	ngAfterViewInit() {
		this.setInputDates();
	}

	public ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges.clearInput && this.clearInput === true) {
			this.selectedRangeValue = { from: undefined, to: undefined };
			this.selectedSingleDate = undefined;
		}
		if (simpleChanges.singleDateInput || simpleChanges.dateRangeInput) {
			this.setInputDates();
		}
	}

	private setInputDates(){
		if (this.singleDate && this.singleDateInput) {
			this.selectedSingleDate = new Date(this.singleDateInput);
			let day: number = this.selectedSingleDate.getDate();
			this.year = this.selectedSingleDate.getFullYear();
			this.month = this.selectedSingleDate.getMonth();
			this.isSelected(day);
		}
		if (this.dateRange && this.dateRangeInput) {
			this.selectedRangeValue.from = new Date(this.dateRangeInput.startDate);
			this.selectedRangeValue.to = new Date(this.dateRangeInput.endDate);
		}
		this.getDateRangeString();
	}

	public toggleDatePicker() {
		this.isOpen = !this.isOpen;
	}

	public prevMonth() {
		if (this.month === 0) {
			this.month = 11;
			this.year--;
		} else {
			this.month--;
		}
		this.clearDaysCache();
	}

	public nextMonth() {
		if (this.month === 11) {
			this.month = 0;
			this.year++;
		} else {
			this.month++;
		}
		this.clearDaysCache();
	}

	// Cache the result of getDaysInMonth to avoid recalculations
	public getDaysInMonth(): { day: number, empty: boolean }[] {
		if (this.daysCache) {
			return this.daysCache;
		}

		const firstDay = new Date(this.year, this.month, 1).getDay();
		const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

		const startDay = (firstDay + 6) % 7;
		const daysArray = [];

		for (let i = 0; i < startDay; i++) {
			daysArray.push({ day: null, empty: true });
		}

		for (let i = 1; i <= daysInMonth; i++) {
			daysArray.push({ day: i, empty: false });
		}

		this.daysCache = daysArray;
		return daysArray;
	}

	public clearDaysCache() {
		this.daysCache = null;
	}

	public selectDate(day: number) {
		const selected = new Date(this.year, this.month, day);

		if (this.singleDate) {
			this.selectedSingleDate = selected;
			this.selectedDate.emit(selected);
			this.isOpen = false;
		} else {
			if (!this.selectedRangeValue.from || this.selectedRangeValue.to) {
				this.selectedRangeValue = { from: selected, to: null };
			} else {
				if (selected < this.selectedRangeValue.from) {
					this.selectedRangeValue = { from: selected, to: null };
				} else {
					this.selectedRangeValue.to = selected;
					this.isOpen = false;
					this.selectedRange.emit(this.selectedRangeValue);
				}
			}
		}
		this.getDateRangeString();
	}

	public isSelected(day: number): boolean {
		const current = new Date(this.year, this.month, day).getTime();

		if (this.singleDate && this.selectedSingleDate) {
			return current === this.selectedSingleDate.getTime();
		}

		if (this.selectedRangeValue.from && this.selectedRangeValue.to) {
			return current === this.selectedRangeValue.from.getTime() || current === this.selectedRangeValue.to.getTime();
		}

		return this.selectedRangeValue.from && current === this.selectedRangeValue.from.getTime();
	}

	public isInRange(day: number): boolean {
		const current = new Date(this.year, this.month, day).getTime();
		if (this.selectedRangeValue.from && this.selectedRangeValue.to) {
			const from = this.selectedRangeValue.from.getTime();
			const to = this.selectedRangeValue.to.getTime();
			return current > from && current < to;
		}
		return false;
	}

	public getDateRangeString() {
		if (this.selectedRangeValue.from && this.selectedRangeValue.to) {
			const from: string = this.dateFormattingService.formatDateString(this.selectedRangeValue.from);
			const to: string = this.dateFormattingService.formatDateString(this.selectedRangeValue.to);
			if (from && to) {
				this.dateRangeString = `${from} - ${to}`;
			} else {
				this.dateRangeString = '';
			}
		} else if (this.selectedSingleDate) {
			this.dateRangeString = this.dateFormattingService.formatDateString(this.selectedSingleDate);
		} else {
			this.dateRangeString = '';
		}
	}

	public getMonthName(month: number): string {
		return new Date(0, month).toLocaleString('default', { month: 'long' });
	}

	public setMonth(month: number) {
		this.month = month;
		this.monthPopover.close();
		this.clearDaysCache();
	}

	public setYear(year: number) {
		this.year = year;
		this.yearPopover.close();
		this.clearDaysCache();
	}

	public openMonthPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.monthPopover.open(event);
	}

	public openYearPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.yearPopover.open(event);
	}
}
